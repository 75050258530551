@import "scss/index";
.footer {
  min-height: 200px;
  z-index: 1;
}

.avatar {
  width: 72px;
  height: 72px;
}

.legal {
  max-width: 536px;
}

//footer + footer {
//  display: none;
//}
